import React from 'react';
import { Row, Col } from 'antd';
import { Document, Page as PDFPage } from 'react-pdf';
import withSizes from 'react-sizes';
import { JSONLD, Generic } from 'react-structured-data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import { Page, Section } from '../components/layout/page';
import Headline from '../components/layout/headline';
import SEO from '../components/content/seo';
import Layout from '../containers/layout';

import styles from './event.module.less';

const EventPage = ({ pageContext, width }) => {
  const event = pageContext.event;

  return (
    <Layout>
      <SEO
        title={event.seo.title}
        description={event.seo.description}
        keywords={event.seo.keywords}
        template={`${event.seo.title} | LiveMenu Event`}
      />
      <JSONLD>
        <Generic
          type='event'
          jsonldtype={event.jsonldtype}
          schema={event.schema}></Generic>
      </JSONLD>
      <Page>
        <Section color='black'>
          <Row gutter={32} type='flex' justify='start'>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Headline
                lead={event.page.lead}
                emphasis={event.page.emphasis}
                subtitle={event.page.subtitle}
                background='black'
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}></Col>
          </Row>
          <Row gutter={32} type='flex' justify='center'>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Document file={event.page.file}>
                <PDFPage pageNumber={1} width={width} />
              </Document>
              <br />
              <h5>
                <a className={styles.download} href={event.page.file}>
                  <FontAwesomeIcon
                    className='list-item-icon white'
                    icon={faDownload}
                  />{' '}
                  Download
                </a>
              </h5>
            </Col>
          </Row>
        </Section>
      </Page>
    </Layout>
  );
};

const mapSizesToProps = ({ width }) => ({
  width: width >= 1200 ? 1136 : width - 64,
});

export default withSizes(mapSizesToProps)(EventPage);
